import "../styles/edit-taxis.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

export default function EditTaxis() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	//
	const [taxis, setTaxis] = useState([]);
	const [images, setImages] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	//> Properties
	const [taxiId, setTaxiId] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [nationalId, setNationalId] = useState(null);
	const [fatherName, setFatherName] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [birthDate, setBirthDate] = useState(null);
	const [gender, setGender] = useState(null);
	const [serialNumber, setSerialNumber] = useState(null);
	const [engineNumber, setEngineNumber] = useState(null);
	const [chassisNumber, setChassisNumber] = useState(null);
	const [productionYear, setProductionYear] = useState(0);
	const [color, setColor] = useState(null);
	const [fuelType, setFuelType] = useState(null);
	const [carType, setCarType] = useState(null);
	const [plateNumber, setPlateNumber] = useState(null);
	//
	const taxiTypes = [
		{ label: "اختصاصی", value: "Special" },
		{ label: "اعتباری", value: "Credit" },
		{ label: "سایر", value: "Other" },
	];
	//> useEffect
	useEffect(() => {
		getData();
	}, [first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			const taxisResult = await adminController.getTaxis(
				Math.floor(first / 12) + 1,
				12
			);
			if (taxisResult.isSuccess === false) throw taxisResult.message;

			let lastRowNumber = first;
			taxisResult.value.map((item) => (item.index = ++lastRowNumber));

			setTaxis(taxisResult.value);
			setTotalRecords(taxisResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> resetTaxi
	const resetTaxi = async function () {
		try {
			setPageType("edit");
			setTaxiId("00000000-0000-0000-0000-000000000000");
			setFullName("");
			setNationalId("");
			setFatherName("");
			setMobile("");
			setBirthDate("");
			setGender("");
			setSerialNumber("");
			setEngineNumber("");
			setChassisNumber("");
			setProductionYear(0);
			setColor("");
			setFuelType("");
			setCarType("");
			setPlateNumber("");
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editTaxi
	const editTaxi = async function (objTaxi) {
		try {
			setPageType("edit");

			setTaxiId(objTaxi.taxiId);
			setFullName(objTaxi.fullName);
			setNationalId(objTaxi.nationalId);
			setFatherName(objTaxi.fatherName);
			setMobile(objTaxi.mobile);
			setBirthDate(objTaxi.birthDate);
			setGender(objTaxi.gender);
			setSerialNumber(objTaxi.serialNumber);
			setEngineNumber(objTaxi.engineNumber);
			setChassisNumber(objTaxi.chassisNumber);
			setProductionYear(objTaxi.productionYear);
			setColor(objTaxi.color);
			setFuelType(objTaxi.fuelType);
			setCarType(objTaxi.carType);
			setPlateNumber(objTaxi.plateNumber);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateTaxi
	const updateTaxi = async function () {
		try {
			let errors = [];

			if (!fullName) errors.push("نام راننده را وارد نمایید");
			if (!nationalId) errors.push("کد ملی را وارد نمایید");
			if (isNaN(Number(productionYear)))
				errors.push("سال تولید باید عددی باشد");
			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت تاکسی",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateTaxiResult = await adminController.updateTaxi(
				taxiId,
				fullName,
				nationalId,
				fatherName,
				mobile,
				birthDate,
				gender,
				serialNumber,
				engineNumber,
				chassisNumber,
				productionYear,
				color,
				fuelType,
				carType,
				plateNumber
			);
			if (updateTaxiResult.isSuccess === false) throw updateTaxiResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت تاکسی",
				detail: localizer("ثبت اطلاعات تاکسی با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	//> cancelTaxi
	const cancelTaxi = function () {
		setPageType("list");
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="taxis">
				{pageType === "list" && (
					<>
						<div>
							<Button
								label="ثبت تاکسی جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetTaxi()}
							/>
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>شماره ملی</th>
									<th>جنسیت</th>
									<th>نام راننده</th>
									<th>موبایل</th>
									<th>نوع خودرو</th>
									<th>رنگ بدنه</th>
									<th>سال تولید</th>
									<th>شماره پلاک</th>
								</tr>
							</thead>
							<tbody>
								{taxis.map((item) => (
									<tr>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editTaxi(item)}
											>
												{item.nationalId}
											</a>
										</td>
										<td>{item.gender}</td>
										<td>{item.fullName}</td>
										<td>{item.mobile}</td>
										<td>{item.carType}</td>
										<td>{item.color}</td>
										<td>{item.productionYear}</td>
										<td>{item.plateNumber}</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>کد ملی</label>
								<IconField iconPosition="right">
									<InputIcon
										className={PrimeIcons.INFO_CIRCLE}
										style={{ color: "red" }}
									>
										{" "}
									</InputIcon>
									<InputMask
										value={nationalId}
										maxLength={10}
										className="ltr"
										onChange={(e) => setNationalId(e.target.value)}
										mask="9999999999"
									/>
								</IconField>
							</div>
							<div>
								<label>نام راننده</label>
								<IconField iconPosition="right">
									<InputIcon
										className={PrimeIcons.INFO_CIRCLE}
										style={{ color: "red" }}
									>
										{" "}
									</InputIcon>
									<InputText
										value={fullName}
										onChange={(e) => setFullName(e.target.value)}
									/>
								</IconField>
							</div>
						</div>

						<div>
							<div>
								<label>نام پدر</label>
								<InputText
									value={fatherName}
									onChange={(e) => setFatherName(e.target.value)}
									invalid={true}
								/>
							</div>
							<div>
								<label>تاریخ تولد</label>
								<InputText
									value={birthDate}
									onChange={(e) => setBirthDate(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>موبایل</label>
								<InputMask
									value={mobile}
									onChange={(e) => setMobile(e.target.value)}
									mask="99999999999"
									required
								/>
							</div>
							<div>
								<label>جنسیت</label>
								<InputText
									value={gender}
									onChange={(e) => setGender(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>شماره سریال</label>
								<InputText
									value={serialNumber}
									onChange={(e) => setSerialNumber(e.target.value)}
								/>
							</div>
							<div>
								<label>شماره موتور</label>
								<InputText
									value={engineNumber}
									onChange={(e) => setEngineNumber(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>شماره شاسی</label>
								<InputText
									value={chassisNumber}
									onChange={(e) => setChassisNumber(e.target.value)}
								/>
							</div>
							<div>
								<label>سال تولید</label>
								<InputMask
									value={productionYear}
									onChange={(e) => setProductionYear(e.target.value)}
									mask="9999"
								/>
							</div>
						</div>

						<div>
							<div>
								<label>نوع خودرو</label>
								<InputText
									value={carType}
									onChange={(e) => setCarType(e.target.value)}
								/>
							</div>
							<div>
								<label>رنگ بدنه</label>
								<InputText
									value={color}
									onChange={(e) => setColor(e.target.value)}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>نوع سوخت</label>
								<InputText
									value={fuelType}
									onChange={(e) => setFuelType(e.target.value)}
								/>
							</div>
							<div>
								<label>شماره پلاک</label>
								<InputText
									value={plateNumber}
									onChange={(e) => setPlateNumber(e.target.value)}
								/>
							</div>
						</div>

						<div className="buttons">
							<Button
								label="ثبت تاکسی"
								onClick={() => updateTaxi("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelTaxi()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
